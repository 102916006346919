<template>
    <div class="container">
        <div v-if="shape==1" class="outerCircle"></div>
        <div :style="{backgroundColor: `${val}`}" :class = "(shape)?'innerCircle':'indicator'"></div>
    </div>
</template>

<script setup>
    import { ref, toRefs, watch } from 'vue';
    const props = defineProps({valin: Number, states: Array, shape: Number});
    const {valin, shape} = toRefs(props);
    let stateArray = [];
    stateArray = props.states;
    let val = ref("");
    
    //Initial value on load
    shape.value = props.shape;
    val.value = stateArray[props.valin];

    //Watch for changes to props
    watch(props, () => {
        val.value = stateArray[props.valin];
        shape.value = props.shape;
    });
</script>

<style lang="scss" scoped>
.container {
    position:relative;
    contain: layout;
}
.indicator {
    position:absolute;
    left: 0%;
    top: 0%;
    height:100%;
    width:100%;
    z-index:1;
    border:2px solid #787878;
    background-color: #787878;
    box-sizing: border-box;
    border-radius: 6px;
}
.outerCircle {
    position: absolute;
    height: 100%;
    width: 100%;
    // left:14%;
    // top:12.5%;
    background-color: #f2f2f2;
    border-radius: 50%;
    display: inline-block;
    z-index: 1;
}
.innerCircle {
  position: absolute;
  height: 84%;
  width: 84%;
  left:8%;
  top:8%;
  background-color: #787878;
  border-radius: 50%;
  display: inline-block;
  z-index: 2;
}
</style>
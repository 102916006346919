<template>
    <div class="container">
        <div :class = "(shape)?'outerCircle':'outerCapsule'"></div>
        <div :class = "(shape)?'innerCircle':'innerCapsule'"></div>
        <div :class = "(shape)?'indicatorCircle':'indicatorCapsule'">{{ val }}</div>
    </div>
</template>

<script setup>
    import { ref, toRefs, watch } from 'vue';
    const props = defineProps({valin: Number, states: Array, shape: Number});
    const {valin, shape} = toRefs(props);
    let stateArray = [];
    stateArray = props.states;
    let val = ref("");
    
    //Initial value on load
    shape.value = props.shape;
    val.value = stateArray[props.valin];

    //Watch for changes to props
    watch(props, () => {
        val.value = stateArray[props.valin];
        shape.value = props.shape;
    });
</script>

<style lang="scss" scoped>
.container {
    position:relative;
    contain: layout;
    overflow: hidden;
}
.indicatorCircle {
    position:absolute;
    left: 12%;
    top: 31%;
    height:10%;
    width:50%;
    z-index:10;
    color: white;
    text-align: center;
    padding: 0 5px;
    word-wrap: break-word;
}
.innerCircle {
  position: absolute;
  height: 100%;
  width: 38%;
  left:18.5%;
  top:17%;
  background-color: #787878;
  border-radius: 5px;
  display: inline-block;
  z-index: 2;
}
.indicatorCapsule {
  position:absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index:10;
  color: white;
  text-align: center;
  font-size: 100%;
  word-wrap: break-word;
  padding: 0 5px;
}
.indicatorCapsule,.indicatorCircle{
  text-align: center;
  color: #fff;
}
.innerCapsule {
  height: 100%;
  width: 100%;
  background-color: #787878;
  border-radius: 5px;
  display: inline-block;
}
</style>
<template>
  <div class="container">
    <div class="custom-select">  
      <select :disabled="!enabled" @change="changeEvent($event.target.value)">
        <template v-for="(item, index) in states">
          <option v-if="startingId === index" selected="selected" :value="index">{{ item }}</option>
          <option v-else :value="index">{{ item }}</option>
        </template>
      </select>
    </div>
  </div>
</template>

<script setup>
    import { defineProps, defineEmits, /*toRefs,*/ watch } from 'vue';

    const props = defineProps({startingId: String, states: Object, enabled: Boolean});
    //const {startingId, states, enabled} = toRefs(props);
    const emit = defineEmits(['selection']);

    //let stateArray = [];
    //stateArray = props.states;

    function changeEvent(id) {
      emit('selection',id);
    }
    
    //Initial value on load
    //states.value = stateArray;
    //startingid.value = props.startingid;
    //enabled.value = props.enabled;

    //Watch for changes to props
    watch(props, () => {
      //states.value = props.stateArray;
      //startingid.value = props.startingid;
    });
</script>

<style lang="scss" scoped>
.container {
  position:relative;
  contain: layout;
}
.custom-select {
  height: 40px;
  border-radius: 10px;
	position: relative;
  select {
    appearance: none;
    /*  safari  */
    -webkit-appearance: none;
    /*  other styles for aesthetics */
    text-indent: 12px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    color: #444;
    cursor: pointer;
    height: 40px;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
  }
}
.custom-select::before{
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
	height: 24px;
	width: 24px;
	top: 50%;
	transform: translateY(-50%);
	right: 12px;
	background-image: url(../../../public/img/arr_down.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center left;
}
</style>
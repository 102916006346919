<template>
  <div class="container">
    <div v-if="title == 'Fuel'" class="outerCircle" style="background-color: transparent"></div>
    <div v-else-if="title == 'MPH'" class="outerCircle" style="background-color: #787878"></div>
    <div v-else class="outerCircle"></div>
    <div class="innerCircle"></div>
    <highcharts class="gauge" :options="chartOptions" ref="highcharts" ></highcharts>
    <div class="text_data">
      <div class="value">{{ val }}<span v-if="title == 'Brake' || title == 'Fuel'">%</span><span v-if="title == 'angle'">°</span></div>
      <div v-if="title == 'Fuel' || title == 'MPH' || title == 'IPS' || title == 'Brake'" class="title_v2">{{ props.title }}</div>
      <div v-else-if="title == 'angle'" style="display: none;">{{ props.title }}</div>
      <div v-else class="title" :style="[title == 'Brake' && val != 0 || title == 'IPS' && val != 0 ? { marginTop: '10px', position: 'absolute', bottom: '9px' } : null]">{{ props.title }}</div>
    </div>
  </div>
</template>

<script setup>
    import { ref, toRefs, watch } from 'vue';
    const props = defineProps({valin: Number, title: String});
    const {valin} = toRefs(props);
    let val = ref(0);
    let titleval = ref("");
    let title = props.title;
    
    let chartOptions = ref({});
    chartOptions.value = {
          chart: {
            type: 'solidgauge',
            backgroundColor: 'rgba(0,0,0,0)',
            height:null,
            width:null,
            animation:true,
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          pane: {
            startAngle: -140,
            endAngle: 140,
            background: [{ 
                outerRadius: '95%',
                innerRadius: '65%',
                backgroundColor:'#787878',
                borderWidth: 0
            },]
          },
          plotOptions: {
            solidgauge: 
            {
              dataLabels: {
                enabled: false
              },
              stickyTracking: false,
              rounded: false,
              animation: false,
            }
          },
          yAxis: 
          {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
          },
          series: [{
            name: 'Percent Complete',
            data: [{
              color: 'white',
              radius: '120%',
              innerRadius: '105%',
              y: 0,
            }],
          }],
    };

    watch(props, () => {
        if(props.valin!==undefined) {
            chartOptions.value.series[0].data[0].y = props.valin;
            val.value = props.valin;
            // titleval.value = props.title;
            
        } else {
            chartOptions.value.series[0].data[0].y = 0;
            val.value = 0;
            // titleval.value = "";
        }
    });

   

</script>

<style lang="scss" scoped>
.container {
    position:relative;
    contain: layout;
}
.gauge {
    position:absolute;
    left: 0%;
    top: 2px;
    height:100%;
    width:100%;
    z-index:3;
}
.text_data{
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title,.value{
    font-size: inherit;
    color:white;
  }
  .value {
    text-align: center;
    width:100%;
    justify-items: center;
    align-content: center;
    flex-grow: 1;
  }
  .title {
    text-align: center;
    width:100%;
  }
  .title_v2 {
    text-align: center;
    width:100%;
    position: absolute;
    bottom: 9px;
    color: #fff;
  }
}
.outerCircle {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 50%;
    display: inline-block;
    z-index: 1;
  }
  .innerCircle {
    position: absolute;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    left: 5px;
    top: 5px;
    background-color: #787878;
    border-radius: 50%;
    display: inline-block;
    z-index: 2;
  }
</style>
<template>
    <div class="container">
        <div class="vehicle">
          <span>FRONT</span>
          <img class="indicator" :src="val">
        </div>
    </div>
</template>

<script setup>
    import { ref, toRefs, watch } from 'vue';
    const props = defineProps({valin: Number, images: Array});
    const {valin} = toRefs(props);
    let imageArray = [];
    imageArray = props.images;
    let val = ref(imageArray[0]);
    watch(props, () => {
        // if(props.valin!==undefined) {
            console.log("OBJECT ARRAY:",props.valin);
            val.value = imageArray[props.valin];
        // }
    });
</script>

<style lang="scss" scoped>
.container {
  position:relative;
  contain: layout;
  .vehicle{
    position:absolute;
    right: 0%;
    bottom: 0;
    height: 100%;
    width: 66%;
    z-index:1;
    >span{
      font-size: 14px;
      line-height: 18px;
      height: 20px;
      font-weight: 500;
      display: inline-block;
      width: 100%;
      text-align: center;
    }
    .indicator {
      height: 70%;
      width: 100%;
    }
  }
}

</style>
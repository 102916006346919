<template>
  <div class="container">
    <div class="innerCircle"></div>
    <div class="text_data" :class="title == 'Direction' || title == 'Drive Mode' ? 'text_data_tall' : null">
      <div class="title">{{ titleval }}</div>
    </div>
    <div class="gage_subtitle" v-if="title == 'Direction'">Direction</div>
    <div class="gage_subtitle" v-if="title == 'Drive Mode'">Drive Mode</div>
  </div>
</template>

<script setup>
    import { ref, toRefs, watch } from 'vue';
    const props = defineProps({valin: Number, title: String});
// const {title} = toRefs(props);
    let title = props.title;
    let titleval = ref("...");
    
    
    watch(props, () => {
      titleval.value = props.valin;
    });

</script>

<style lang="scss" scoped>
.container {
  position:relative;
  contain: layout;
  .gage_subtitle{
    color:white;
    font-size: .75rem;
    position: absolute;
    z-index: 5;
    bottom: 10px;
    left: 0px;
    right: 0px;
    text-align: center;
  }
}
.text_data{
  position: absolute;
  z-index: 5; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title{
    font-size: inherit;
    color:white;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
}
.text_data_tall{
  top: 35%;
}
  .innerCircle {
    position: absolute;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    left: 5px;
    top: 5px;
    background-color: #787878;
    border-radius: 8px;
    display: inline-block;
    z-index: 2;
  }
</style>
<template>
    <div class="container">
        <img class="back" src="../boeing/assets/levelback1.svg">
        <div class="back-mask"></div>
        <img class="front-profile"  src="../boeing/assets/lcl-front-profile.svg">
        <img class="needle" :style="{rotate: `${gval}deg`}" src="../boeing/assets/levelNeedle5Degree1Int.svg">
        <div class="value">{{ val }}&deg;</div>
        <div class="title">Roll</div>
        
    </div>
</template>

<script setup>
import { ref, toRefs, watch } from 'vue';
const props = defineProps({ valin: Number});
const { valin } = toRefs(props);

let gval= ref(0);
let val= ref(0);

watch(props, () => {
    gval.value = 10*props.valin;
    val.value = props.valin;
});
</script>

<style lang="scss" scoped>
.container {
    position:relative;
    contain: layout;
    border-radius: 100%;
    overflow: hidden;
}
.back {
    position:absolute;
    left: 0%;
    top: 0%;
    height:100%;
    width:100%;
    z-index:1;
}
.back-mask {
    position:absolute;
    left: 0;
    bottom: 0;
    height: 31%;
    width: 100%;
    z-index:3;
    background-color: #787878;
}
.front-profile {
    position: absolute;
    left: 0%;
    top: -30%;
    height:100%;
    width:100%;
    z-index: 4;
}
.needle {
    position: absolute;
    left: 8%;
    top: 8%;
    height:85%;
    width:85%;
    z-index: 2;
}
.value {
    position: absolute;
    text-align: center;
    left: 0%;
    top: 70%;
    height:100%;
    width:100%;
    z-index: 5;
    color: white;
    font-size: 12px;
    margin-top: -1px;
}
.title {
    position: absolute;
    text-align: center;
    left: 0%;
    top: 80%;
    height:100%;
    width:100%;
    z-index: 5;
    color: white;
    font-size: 12px;
}
</style>
<template>
	<div class="wrapper">
		<div class="horizontal_line"></div>
		<div class="central_line"></div>

		<div class="left_col">
			<div class="vertical_line"></div>

			<div class="tank">
				<div class="vol_wrapper">
					<div class="vol" :style="{ height: `${leftMast}%` }"></div>
				</div>
			</div>
			<div class="valve valve_one">
				<svg width="100%" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.10059 2.28214H3.1857L5.00662 4.55828L10.6105 11.5631L11.7045 12.9306L12.7984 11.5631L18.4023 4.55828L20.2232 2.28214H17.3083H6.10059Z" :fill="problemValve1 == 1 ? '#6BB78B' : 'red'" stroke="#787878" stroke-width="2.80193"/>
					<path d="M6.10059 24.6964H3.1857L5.00662 22.4202L10.6105 15.4154L11.7045 14.0479L12.7984 15.4154L18.4023 22.4202L20.2232 24.6964H17.3083H6.10059Z" :fill="problemValve1 == 1 ? '#6BB78B' : 'red'" stroke="#787878" stroke-width="2.80193"/>
				</svg>
			</div>
			<div class="valve valve_two">
				<svg width="100%" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.10059 2.28214H3.1857L5.00662 4.55828L10.6105 11.5631L11.7045 12.9306L12.7984 11.5631L18.4023 4.55828L20.2232 2.28214H17.3083H6.10059Z" :fill="problemValve3 == 1 ? '#6BB78B' : 'red'" stroke="#787878" stroke-width="2.80193"/>
					<path d="M6.10059 24.6964H3.1857L5.00662 22.4202L10.6105 15.4154L11.7045 14.0479L12.7984 15.4154L18.4023 22.4202L20.2232 24.6964H17.3083H6.10059Z" :fill="problemValve3 == 1 ? '#6BB78B' : 'red'" stroke="#787878" stroke-width="2.80193"/>
				</svg>
			</div>
			<div class="ellipse"></div>
			<div class="rhombus">
				<svg width="100%" height="100%" viewBox="0 0 28 30" xmlns="http://www.w3.org/2000/svg">
					<path d="M14.7284 3.59536L13.7042 2.49803L12.68 3.59536L2.87328 14.1026L1.9811 15.0585L2.87328 16.0144L12.68 26.5217L13.7042 27.619L14.7284 26.5217L24.5352 16.0144L25.4273 15.0585L24.5352 14.1026L14.7284 3.59536Z" :class="dirtyFilter == 1 ? 'blink' : null" :fill="dirtyFilter != 1 ? '#fff' : 'red'" stroke="#787878" stroke-width="2.80193"/>
					<rect x="10.9033" y="14.3589" width="1.40097" height="1.40097" fill="#787878"/>
					<rect x="6.69824" y="14.3589" width="1.40097" height="1.40097" fill="#787878"/>
					<rect x="15.1035" y="14.3589" width="1.40097" height="1.40097" fill="#787878"/>
					<rect x="19.3066" y="14.3589" width="1.40097" height="1.40097" fill="#787878"/>
				</svg>
			</div>
			<div class="end"></div>
		</div>

		<div class="right_col">
			<span class="vertical_line"></span>

			<div class="tank">
				<div class="vol_wrapper">
					<div class="vol" :style="{ height: `${leftMast}%` }"></div>
				</div>
			</div>
			<div class="valve valve_one">
				<svg width="100%" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.10059 2.28214H3.1857L5.00662 4.55828L10.6105 11.5631L11.7045 12.9306L12.7984 11.5631L18.4023 4.55828L20.2232 2.28214H17.3083H6.10059Z" :fill="problemValve2 == 1 ? '#6BB78B' : 'red'" stroke="#787878" stroke-width="2.80193"/>
					<path d="M6.10059 24.6964H3.1857L5.00662 22.4202L10.6105 15.4154L11.7045 14.0479L12.7984 15.4154L18.4023 22.4202L20.2232 24.6964H17.3083H6.10059Z" :fill="problemValve2 == 1 ? '#6BB78B' : 'red'" stroke="#787878" stroke-width="2.80193"/>
				</svg>
			</div>
			
		</div>
		<div class="main_tank">
			<div class="wing">
				<svg width="100%" height="100%" viewBox="0 0 10 39" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.771484 11.9292L0.771484 0.721469L9.17728 6.32533L0.771484 11.9292Z" fill="#787878"/>
					<path d="M0.771484 38.5474L0.771484 27.3396L9.17728 32.9435L0.771484 38.5474Z" fill="#787878"/>
					<path d="M9.17728 32.9431V6.32471L0.771484 11.9286V27.3392L9.17728 32.9431Z" fill="#6BB78B"/>
				</svg>
			</div>
			<div class="negative_value" :style="{ height: `${(mastLevel/-3)}%` }"></div>
			<div class="border border_top"></div>
			<div class="border border_bottom"></div>
		</div>
		<div class="data hyd_level">{{ typeof hydLevel !== 'string' ? `${hydLevel} in` : hydLevel}}</div>
		<div class="data hyd_pressure">{{ typeof hydPressure !== 'string' ? `${hydPressure} psi` : hydPressure}}</div>
		<div class="data mast_level">{{ typeof mastLevel !== 'string' ? `${mastLevel} in` : mastLevel}}</div>
		<div class="data nit_pressure">{{ typeof nitPressure !== 'string' ? `${nitPressure} psi` : nitPressure}}</div>
		<div class="n2">N2</div>
	</div>
</template>

<script setup>
import { ref, toRefs, watch, defineProps } from 'vue';
const props = defineProps(
	{
		hydPressure: Number,
		nitPressure: Number,
		mastLevel: Number,
		hydLevel: Number,
		leftMast: Number,
		dirtyFilter: Number, 
		problemValve1: Number,
		problemValve2: Number,
		problemValve3: Number
	});

// let {
// 		hydPressure,
// 		nitPressure,
// 		mastLevel,
// 		hydLevel,
// 		leftMast,
// 		dirtyFilter, 
// 		problemValve1,
// 		problemValve2,
// 		problemValve3
// 	} = toRefs(props);

let hydPressure = ref(0);
let nitPressure = ref(0);
let mastLevel = ref(0);
let hydLevel = ref(0);
let leftMast = ref(0);
let dirtyFilter = ref(0); 
let problemValve1 = ref(0);
let problemValve2 = ref(0);
let problemValve3 = ref(0);

watch(props, () => {
	hydPressure.value = props.hydPressure;
	nitPressure.value = props.nitPressure;
	mastLevel.value = props.mastLevel;
	hydLevel.value = props.hydLevel;
	leftMast.value = props.leftMast;
	dirtyFilter.value = props.dirtyFilter; 
	problemValve1.value = props.problemValve1;
	problemValve2.value = props.problemValve2;
	problemValve3.value = props.problemValve3;
});

// let data = ref({
// 	hydPressure: 2192,
// 	nitPressure: 2200,
// 	mastLevel:-120,
// 	hydLevel:	83.2,
// 	leftMast:	40,
// 	dirtyFilter: 0, 
// 	problemValve1: 1,
// 	problemValve2: 0,
// 	problemValve3: 0
// })
</script>

<style lang="scss" scoped>
.wrapper{
	width: 100%;
	position: relative;
	aspect-ratio: 4/3;
	border: 14px solid #F2F2F2;
	box-sizing: border-box;
	border-radius: 28px;
	.n2{
		height: 15%;
		aspect-ratio: 16/9;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #787878;
		background-color: #bcbcbc;
		border: 3px solid #787878;
		border-radius: 9px;
		position: absolute;
		bottom: 5%;
		left: 50%;
		transform: translateX(-50%);
		box-sizing: border-box;
		font-weight: 500;
	}
	.data{
		height: 15%;
		aspect-ratio: 11/4;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		background-color: #787878;
		border-radius: 9px;
		position: absolute;
		font-weight: 500;
	}
	.hyd_level,.hyd_pressure{
		left: 50%;
		transform: translateX(-50%);
	}
	.hyd_level{
		top: 5%;
	}
	.hyd_pressure{
		top: 27%;
	}
	.mast_level,.nit_pressure{
		right: 5%;
	}
	.mast_level{
		bottom: 27%;
	}
	.nit_pressure{
		bottom: 5%;
	}
	.horizontal_line{
		position: absolute;
		left: 15.5%;
		right: 15.5%;
		top: 46%;
		height: 3px;
		background-color: #787878;
		box-sizing: border-box;
	}
	.central_line{
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 40%;
		bottom: 10%;
		width: 3px;
		background-color: #787878;
		box-sizing: border-box;
	}
	.tank{
		width: 100%;
		aspect-ratio: 9/10;
		border-radius: 9px;
		border: 3px solid #787878;
		box-sizing: border-box;
		position: absolute;
		top: 5%;
		background-color: #787878;
		overflow: hidden;
		.vol_wrapper{
			position: absolute;
			height: 80%;
			bottom: 0px;
			left: 0px;
			right: 0px;
			.vol{
				max-height: 100%;
				min-height: 4%;
				background-color: #fff;
				position: absolute;
				bottom: 0px;
				left: 0px;
				right: 0px;
			}
		}
	}
	.main_tank{
		width: 17%;
		aspect-ratio: 9/10;
		border-radius: 9px;
		border: 3px solid #787878;
		box-sizing: border-box;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 23%;
		background-color: #bcbcbc;
		.wing{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: calc(-15% - 1px);
			height: 46%;
			width: 16%;
		}
		.negative_value{
			position: absolute;
			width: 100%;
			top: 0;
			border-radius: 8px 8px 0 0;
			background-color: #f2f2f2;
		}
		.border{
			height: 9%;
			left: -1px;
			right: -1px;
			position: absolute;
			background-image: url(../../../public/img/boeing/ma/dotted_border.svg);
      background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
		.border_top{
			top: 33%;
			transform: translateY(-50%);
		}
		.border_bottom{
			bottom: 33%;
			transform: translateY(50%);
		}
	}
	.left_col{
		left: 7%;
		overflow: hidden;
		.vertical_line{
			height: 100%;
		}
		.ellipse{
			position: absolute;
			background-image: url(../../../public/img/boeing/ma/ellipse.svg);
      background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
			width: 35%;
			aspect-ratio: 1/1;
			left: 50%;
			transform: translateX(-49%);
			top: 63%;
		}
		.rhombus{
			position: absolute;
			width: 50%;
			aspect-ratio: 1/1;
			left: 50%;
			transform: translateX(-49%);
			top: 73%;
		}
		.end{
			position: absolute;
			width: 50%;
			aspect-ratio: 1/1;
			left: 50%;
			transform: translateX(-50%);
			top: 86%;
			background-image: url(../../../public/img/boeing/ma/end.svg);
      background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
		.end::after{
			content: '';
			display: block;
			height: 30px;
			width: 30px;
			position: absolute;
			background-color: #fff;
			bottom: 0px;
			transform: translateY(99%);
		}
		.blink{
			animation: blinker 1s linear infinite;
		}
		@keyframes blinker {
			50% {
				opacity: 0;
			}
		}
	}
	.right_col{
		right: 7%;
		.vertical_line{
			height: calc(46% + 3px);
		}
	}
	.valve{
		width: 35%;
		aspect-ratio: 3/4;
		position: absolute;
		left: 50%;
		transform: translateX(-49%);
	}
	.left_col,.right_col{
		position: absolute;
		top: 0px;
		width: 17%;
		bottom: 0;
		.vertical_line{
			position: absolute;
			left: calc(50% - 1.5px);
			width: 3px;
			background-color: #787878;
			box-sizing: border-box;
		}
		.valve_one{
			top: 35%;
		}
		.valve_two{
			top: 50%;
		}
	}
}
</style>